<template lang="pug">
div(class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="(!closed && success) || (success && reload)")
  div(class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity")

  div(class="fixed inset-0 z-10 overflow-y-auto")
    div(class="flex min-h-full justify-center p-4 text-center items-center sm:p-0")
      div(class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg")
        div(class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4")
          div(class="flex w-full justify-center")
            div(class="mt-3 sm:mt-0 sm:text-left")
              .flex.justify-center
                CheckCircleIcon.h-12.w-12.text-successgreen
              div(class="mt-2")
                p(class="text-sm text-gray-500 text-center") {{ message }}
        div(class="bg-gray-50 px-4 py-3 flex justify-center sm:px-6")
          button(@click="close()" type="button" class="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 w-auto") Fermer
</template>

<script setup>
  import { CheckCircleIcon } from '@heroicons/vue/20/solid'
  const props = defineProps({
    message: { type: String, required: true },
    success: { type: Boolean, required: true },
    reload: { type: Boolean, required:false, default: true },
  })
  const closed = ref(false)

  const close = () => {
    closed.value = true
    if (props.reload) {
      location.reload()
    }
  }

</script>
